import { defineStore } from "pinia/dist/pinia";
import { UploadFileInfo } from 'naive-ui';
import { RelateFilesId } from '../types'

const useUploadFileStatus = defineStore({
    id: 'selectedFiles',
    persist: {
        key: 'selectedFilesKey',
        storage: sessionStorage,
        paths: ['selectedFiles','translanguage1','translanguage2']
    },
    state: () => ({
        selectedFiles: [] as UploadFileInfo[],
        uploadFiles: [] as UploadFileInfo[],
        geneticFiles: [] as UploadFileInfo[],
        relateFilesId: [] as RelateFilesId[],
        relateGeneticFilesId: [] as any[],
        fromGeneticCounseling: false,  //设置是否来自遗传咨询
        translanguage1: "English",
        translanguage2: "Chinese",
        uploadPath: 'api/files/local/upload'
    }),
    getters: {
        translanguage3(state) {
          return `api/upload/${state.translanguage1}_${state.translanguage2}`;
        }
    },
    actions: {
        updateUploadFiles(files: Array<UploadFileInfo>) {
            this.uploadFiles = files;
        },
        setFromGeneticCounseling(status: boolean) {
            this.fromGeneticCounseling = status;
        }
    }
});

export default useUploadFileStatus;
